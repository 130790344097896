import "../css/app.css"; // eslint-disable-line no-unused-vars

globalThis.addEventListener("DOMContentLoaded", () => {
  prepareLoader();
  // setWindow();
  prepareMap();
  dismissFlash();
  prepareSearch();

  const userState = document.getElementById("user-state");
  if (userState !== null) {
    fetch("/auth/user")
      .then((res) => res.text())
      .then((text) => {
        userState.innerHTML = text;
        dropdown();
      });
  }
});

// const setWindow = () => {
//   const mediaQueryList = window.matchMedia("(max-width: 640px)");
//   const mediaListener = (e) => {
//     if (e.matches) {
//       console.log("SP");
//     } else {
//       console.log("PC");
//     }
//   };
//   mediaQueryList.addEventListener("change", mediaListener);
//   mediaListener(mediaQueryList);
// };

const prepareLoader = () => {
  const loaderMask = document.createElement("div");
  loaderMask.setAttribute("id", "mask");
  loaderMask.classList.add("ready", "active");
  const loader = document.createElement("div");
  loader.setAttribute("id", "loader");
  const spinner = document.createElement("div");
  spinner.setAttribute("id", "spinner");
  loader.appendChild(spinner);
  loaderMask.appendChild(loader);
  document.body.appendChild(loaderMask);
  globalThis.addEventListener("load", () => {
    loaderMask.classList.remove("active");
    setTimeout(() => {
      loaderMask.parentNode !== null ? loaderMask.parentNode.removeChild(loaderMask) : null;
    }, 200);
  });
};

const dropdown = () => {
  const target = document.querySelector(".dropdown");
  if (target) {
    const contents = target.querySelector("ul") as HTMLUListElement;
    target.addEventListener("click", () => {
      createMask(contents);
    });
    const createMask = (contents: HTMLElement) => {
      contents.style.display = "block";
      const mask = document.createElement("div");
      mask.setAttribute("id", "mask");
      document.body.appendChild(mask);
      mask.classList.add("ready");
      setTimeout(() => {
        mask.classList.add("active");
      }, 100);
      mask.addEventListener("click", () => {
        contents.style.display = "none";
        mask.classList.remove("active");
        setTimeout(() => {
          mask.parentNode !== null ? mask.parentNode.removeChild(mask) : null;
        }, 200);
      });
    };
  }
};

const prepareSearch = () => {
  const target = document.querySelector(".fa-search");
  if (target !== null) {
    target.addEventListener("click", () => {
      const form = document.createElement("form");
      form.setAttribute("action", "/search");
      form.setAttribute("method", "get");
      const input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("name", "q");
      form.setAttribute("id", "q");
      form.appendChild(input);
      const mask = document.createElement("div");
      mask.setAttribute("id", "mask");
      mask.appendChild(form);
      document.body.appendChild(mask);
      mask.classList.add("ready");
      setTimeout(() => {
        mask.classList.add("active");
      }, 100);
      input.focus();
      input.addEventListener("blur", () => {
        mask.classList.remove("active");
        setTimeout(() => {
          mask.parentNode !== null ? mask.parentNode.removeChild(mask) : null;
        }, 200);
      });
    });
  }
};

const dismissFlash = () => {
  if (document.querySelector(".hide-alert") != null) {
    document.querySelectorAll(".hide-alert").forEach((elem) => {
      elem.addEventListener("click", (e) => {
        e.preventDefault();
        const el = e.currentTarget as HTMLElement;
        const parent = el.parentNode as HTMLElement;
        parent.style.display = "none";
      });
    });
  }
};

const prepareMap = () => {
  const map = document.createElement("div");
  map.setAttribute("id", "googlemap");
  map.innerHTML =
    `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.3585116068157!2d135.402814751301!3d34.87234268141652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000f637b296b453%3A0x92f55abb26eee8b8!2z5p2-5LqV6YWS6LKp5qCq5byP5Lya56S-!5e0!3m2!1sen!2sjp!4v1555556925323!5m2!1sen!2sjp" frameborder="0" style="border: 0" allowfullscreen></iframe>`;
  const mapIcon = document.getElementById("map");
  if (mapIcon) {
    mapIcon.addEventListener("click", (e) => {
      e.preventDefault();
      openModal(map);
    });
  }
};

const openModal = (contents: HTMLElement) => {
  const mask = document.createElement("div");
  const modal = document.createElement("div");
  const close = document.createElement("i");
  close.classList.add("fas", "fa-times-circle", "close");
  mask.setAttribute("id", "mask");
  modal.setAttribute("id", "modal");
  modal.appendChild(contents);
  modal.appendChild(close);
  document.body.appendChild(mask);
  document.body.appendChild(modal);
  mask.classList.add("ready");
  setTimeout(() => {
    mask.classList.add("active");
  }, 100);
  [mask, close].forEach((i) => {
    i.addEventListener("click", () => {
      if (modal.parentNode) {
        modal.parentNode.removeChild(modal);
        mask.classList.remove("active");
        setTimeout(() => {
          mask.parentNode !== null ? mask.parentNode.removeChild(mask) : null;
        }, 200);
      }
    });
  });
};

